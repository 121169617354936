<template>
  <AccountActionResultStatusHeader>
    <slot name="header" />
  </AccountActionResultStatusHeader>
  <AccountActionResultStatusContent>
    <slot name="content" />
  </AccountActionResultStatusContent>
</template>

<script setup lang="ts"></script>
